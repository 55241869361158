/** Hero section **/
.hero-section {
  background: url('../assets/images/hero-image-mobile.jpg') no-repeat center top;
  background-size: cover;
  min-height: 650px;
  height: calc(100vh - 100px);
  display: flex;
  align-items: flex-end;
  padding: 50px 0;
  color: $color-white;
  text-align: center;
  position: relative;

  &:before {
    background: transparentize($color:$color-black, $amount: 0.4);
    background: linear-gradient(65deg, rgba(51, 51, 51, 1) 0%, rgba(255, 255, 255, 0) 45%);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    z-index: 0;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  p {
    margin-bottom: 80px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .hero-section {
    background-image: url('../assets/images/hero-image.jpg');

    p {
      font-size: 1.5rem;
      margin-bottom: 3rem;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .hero-section {
    align-items: center;
    text-align: left;
  }
}

/** Section What we Offer **/
.section-we-offer {
  padding: 2rem 0;

  .title-wrapper {
    text-align: center;
  }
}

.essential-accordion {
  border: 1px solid $color-accordion-bdr;

  .card {
    border-bottom: 1px solid $color-accordion-bdr;

    &:last-child {
      border-bottom: none;
    }
  }

  .accordion-trigger {
    background-color: $color-green;
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 60px auto;
    border: none;
    color: $color-white;
    font-size: 1.25rem;
    font-family: $font-roboto;
    font-weight: $weight-bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 150px;
    padding: 20px 20px 20px 85px;
    text-align: left;
    transition: $global-transition;
    outline: 0;
    width: 100%;

    .indicator {
      color: $color-white;
      background-color: $color-green;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 35px;
      height: 35px;
      width: 35px;
      opacity: 0;
      transition: $global-transition;
    }
  }

  .accordion-trigger.collapsed {
    background-color: transparent;
    color: $color-grey;

    .indicator {
      opacity: 1;
    }
  }

  .accordion-trigger.admin {
    background-image: url('../assets/images/feature-admin.png');

    &.collapsed {
      background-image: url('../assets/images/feature-admin-green.png');
    }
  }

  .accordion-trigger.powerful {
    background-image: url('../assets/images/feature-report.png');

    &.collapsed {
      background-image: url('../assets/images/feature-report-green.png');
    }
  }

  .accordion-trigger.timecard {
    background-image: url('../assets/images/feature-time-card.png');

    &.collapsed {
      background-image: url('../assets/images/feature-time-card-green.png');
    }
  }

  .card-body {
    padding: 30px 15px;

    .intro {
      border-bottom: 1px solid $color-very-light-grey;
      font-size: 1.125rem;
      font-weight: $weight-bold;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }

    ul {
      list-style-image: url('../assets/images/list-image.png');
      margin-bottom: 0;
    }

    li {
      padding-left: 15px;
      margin-bottom: 15px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .essential-accordion {
    position: relative;

    .card {
      border-bottom: none;
    }

    .card-header {
      border-bottom: 1px solid $color-accordion-bdr;
      border-right: 1px solid $color-accordion-bdr;
      width: 350px;
      max-width: 100%;
    }

    .card:last-child .card-header {
      border-bottom: none;
    }

    .accordion-trigger {
      background-position: 25px center;
      height: 200px;
      padding: 20px 20px 20px 110px;
    }

    .card-body-wrapper {
      background-color: $color-white;
      position: absolute;
      right: 0;
      top: 0;
      width: calc(100% - 350px);
    }

    .card-body {
      padding: 30px;

      .intro {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
      }

      li {
        margin-bottom: 25px;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .essential-accordion {
    .card-header {
      width: 450px;
    }

    .accordion-trigger {
      background-position: 38px center;
      background-size: 68px auto;
      height: 180px;
      padding: 20px 38px 20px 140px;
    }

    .card-body-wrapper {
      width: calc(100% - 450px);
    }
  }
}

/** Section How it works **/
.section-how-works {
  background: url('../assets/images/how-works-mobile.jpg') no-repeat;
  background-size: cover;
  color: $color-white;
  padding: 3rem 0;
  text-align: center;

  .cnt-left {
    .video-wrapper {
      margin-bottom: 2rem;
    }
  }

  .cnt-right {
    display: none;
  }

  p {
    margin-bottom: 2rem;
  }

  iframe {
    max-width: 100%;
    height: 190px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .section-how-works {
    .cnt-left {
      .video-wrapper {
        display: none;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 992px) {
  .section-how-works {
    background-image: url('../assets/images/how-works.jpg');
    text-align: left;
    padding: 50px;

    iframe {
      max-width: 100%;
      width: 100%;
      height: 400px;
    }

    .cnt-right {
      display: block;

      .video-wrapper {
        margin-left: 64px;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .section-how-works {
    padding: 100px 0;
  }
}

/** Section our story **/
.section-our-story {
  background: url('../assets/images/farm-bg.jpg') no-repeat center center;
  background-size: cover;
  color: $color-white;
  text-align: center;
  padding: 2rem 0;
  position: relative;

  &:before {
    background-color: transparentize($color-green, 0.3);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .container {
    position: relative;
    z-index: 5;
  }

  .cnt-left {
    display: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .section-our-story {
    text-align: left;

    .cnt-left {
      display: block;
    }

    .cnt-right {
      .img-holder {
        display: none;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .section-our-story {
    .cnt-left {
      .img-holder {
        margin: 0 -15px;
      }

      img {
        max-width: calc(100% + 30px);
        margin-left: -15px;
      }
    }

    .cnt-right {
      .content-inner {
        padding-left: 30px;
      }
    }
  }
}

/** Section procing **/
.section-pricing {
  padding: 2rem 0;

  .pricing-wrapper {
    text-align: center;
    box-shadow: 0 4px 6px 0 transparentize($color-black, 0.9);
    max-width: 330px;
    margin: 0 auto;
    width: 100%;
  }

  .item {
    border: 1px solid $color-very-light-grey;
    padding: 2rem 1rem;
    transition: $global-transition;

    &:nth-child(2) {
      border-top: none;
      border-bottom: none;
    }

    .user {
      font-size: 1.875rem;
      font-weight: $weight-bold;
      font-family: $font-roboto;
      color: $color-green;
      margin-bottom: 2rem;
      transition: $global-transition;
    }

    .price-small {
      color: $color-green;
      font-size: 1.5rem;
      font-family: $font-roboto;
      width: 100%;
      transition: $global-transition;
    }

    .note {
      display: block;
      font-size: 1.125rem;
      margin-bottom: 20px;
    }
  }

  .item:hover {
    background-color: $color-green;
    border-color: $color-green;
    color: $color-white;
    transform: scale(1.1);
    z-index: 5;

    .user {
      color: $color-white;
    }

    .price-small {
      color: $color-white;
    }

    .price .small {
      color: $color-white;
    }

    .select-plan {
      background-color: $color-white;
      color: $color-green;
    }
  }

  .price-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
  }

  .price {
    display: flex;
    align-self: center;
    justify-content: center;
    font-size: 3.75rem;
    font-family: $font-roboto;
    line-height: 1.2em;

    .small {
      color: $color-green;
      font-size: 2.25rem;
      transition: $global-transition;
    }
  }

  .c-btn {
    min-width: 250px;
    box-shadow: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .section-pricing {
    .pricing-wrapper {
      display: flex;
      max-width: none;
    }

    .item {
      width: 33.33%;
      max-width: none;

      &:nth-child(2) {
        border: 1px solid $color-very-light-grey;
        border-left: none;
        border-right: none;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .section-pricing {
    .pricing-wrapper {
      margin: 1rem 60px;
    }
  }
}

/** Section Testimonials **/
.section-testimonial {
  background-color: $color-testimonial-bg;
  padding: 2rem 0;
  text-align: center;

  .carousel {
    padding-top: 1rem;
    padding-bottom: 60px;
  }

  .carousel-item {
    padding: 0 30px;
  }

  .carousel-indicators {
    align-items: center;
    margin-bottom: 0;

    li {
      height: 10px;
      width: 10px;
      background: $color-light-grey;
      border: none;
      border-radius: 50%;
      transition: $global-transition;
    }

    .active {
      background: $color-green;
      height: 15px;
      width: 15px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    background-repeat: no-repeat;
    opacity: 1;
  }

  .carousel-control-prev {
    background-image: url('../assets/images/arrow-left.png');
    background-position: left 3rem;
  }

  .carousel-control-next {
    background-image: url('../assets/images/arrow-right.png');
    background-position: right 3rem;
  }

  .quote {
    font-style: normal;
    padding-bottom: 2rem;
  }

  .name {
    color: $color-green;
    display: block;
    font-family: $font-roboto;
    font-weight: $weight-bold;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .section-testimonial {
    background-image: url('../assets/images/testimonial-bg.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 400px;

    .quote {
      max-width: 630px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      padding: 1rem 0;

      &:before, &:after {
        background-repeat: no-repeat;
        position: absolute;
        height: 33px;
        width: 38px;
        z-index: 1;
      }

      &:before {
        background-image: url('../assets/images/quote-start.png');
        content: '';
        left: -50px;
        top: 0;
      }

      &:after {
        background-image: url('../assets/images/quote-end.png');
        content: '';
        right: -50px;
        bottom: 0;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .section-testimonial {
    background-size: contain;
  }
}

/** Section try out **/
.section-tryout {
  background: url('../assets/images/farm-bg.jpg') no-repeat center center;
  background-size: cover;
  color: $color-white;
  text-align: center;
  padding: 2.5rem 0 3rem;
  position: relative;

  &:before {
    background-color: transparentize($color-green, 0.3);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .container {
    position: relative;
    z-index: 5;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .section-tryout {
    text-align: left;
    padding: 100px 0;

    .content-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
