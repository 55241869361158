/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1, h2, h3, h4, h5, h6 {
  font-family: $font-roboto;
  line-height: 1.222em;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 1.8rem;
  }
    
  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.375rem;
  }

  h6 {
    font-size: 1.125rem;
  }
}
