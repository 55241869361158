.green {
  color: $color-green;
}

.upper {
  text-transform: uppercase;
}

.mb0 {
  margin-bottom: 0;
}

.subtitle {
  font-family: $font-lato;
  font-weight: $weight-normal;
  text-transform: uppercase;
  font-size: 1rem;
}
