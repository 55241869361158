/* ==========================================================================
   #HEADER
   ========================================================================== */
.header {
  border-bottom: 1px solid $color-very-light-grey;

  .container {
    position: relative;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;

    a {
      display: inline-block;
      max-width: 200px;
      margin-right: 40px;
    }
  }

  .main-menu-wrapper {
    padding: 0;
  }
}

.menu-trigger {
  cursor: pointer;
  right: 15px;
  position: absolute;
  top: 50px;
  display: flex;
  margin-top: -20px;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-grey;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 0;
  outline: 0;

  &:focus {
    outline: none;
  }
}

.main-menu {
  background-color: $color-green;
  margin: 0;
  text-align: center;

  li {
    margin-bottom: 0;
  }

  a {
    border-bottom: 2px solid $color-white;
    color: $color-white;
    font-weight: $weight-bold;
    text-transform: uppercase;
    padding: 1em 0;

    &:hover, &:focus {
      background-color: #fff;
      color: $color-grey;
    }
  }

  li:last-child {
    a {
      border-bottom: none;
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 992px) {
  .menu-trigger {
    display: none;
  }

  .header {
    .logo-wrapper {
      display: block;
      height: auto;

      a {
        max-width: none;
        margin-right: 0;
      }
    }
  }

  .main-menu-wrapper {
    .navbar-collapse {
      justify-content: flex-end;
    }

    .navbar-nav .nav-link {
      padding: 35px 10px;
    }
  }

  .main-menu {
    background-color: transparent;

    a {
      border-bottom: 4px solid transparent;
      color: $color-grey;
      font-size: 14px;
      transition: $global-transition;

      &:hover, &:focus {
        border-color: $color-green;
      }
    }

    li:last-child {
      a {
        border-bottom: 4px solid transparent;
      }

      a:hover, a:focus {
        border-color: $color-green;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .main-menu-wrapper {
    .navbar-nav .nav-link {
      padding: 50px 20px;
    }
  }
}
