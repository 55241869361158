/* ==========================================================================
   #FOOTER
   ========================================================================== */

.footer {
  text-align: center;
  color: $color-footer-text;
  font-size: 14px;
}

.footer-top {
  border-bottom: 1px solid $color-very-light-grey;

  .logo-col {
    border-bottom: 1px solid $color-very-light-grey;
  }

  .logo-wrapper {
    padding: 20px 0;

    a {
      display: block;
    }
  }
}

.footer-menu {
  margin: 20px 0;

  ul {
    margin: 0;
  }

  li {
    width: 100%;
  }

  a {
    color: inherit;
    font-weight: $weight-bold;
    text-transform: uppercase;

    &:hover, &:focus {
      background: transparent;
      color: darken($color-footer-text, 20%);
    }
  }
}

.footer-bottom {
  padding: 20px 0 50px;

  .copyright {
    margin-bottom: 5px;
  }

  .bottom-menu {
    .nav {
      justify-content: center;
      margin: 0;
    }

    li {
      margin-bottom: 0;
    }

    a {
      text-transform: uppercase;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .footer-top {
    .logo-col {
      border-bottom: none;
    }
  }

  .footer-menu {
    .nav {
      justify-content: flex-end;
    }

    li {
      width: auto;
    }

    a {
      padding: 0 10px;
    }
  }

  .footer-bottom {
    .copyright {
      text-align: left;
    }

    .bottom-menu {
      .nav {
        justify-content: flex-end;
      }

      a {
        padding: 0 10px;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 1200px) {
  .footer-menu {
    a {
      padding: 0 20px;
    }
  }

  .footer-bottom {
    .bottom-menu {
      a {
        padding: 0 20px;
      }
    }
  }
}
