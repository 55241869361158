/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 1.25rem 1.6rem;
  transition: $global-transition;
  border-radius: 30px;
  min-width: 270px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  background-color: $color-green;
  text-transform: uppercase;
  font-weight: $weight-bold;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: lighten($color: $color-green, $amount: 10);
  }
}

.c-btn--secondary {
  background-color: $color-white;
  text-transform: uppercase;
  font-weight: $weight-bold;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-green;
  }

  &:hover,
  &:focus {
    background-color: $color-green;
    color: $color-white;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}
